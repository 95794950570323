import React from "react";
import { Box, Grid, Button, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from "devextreme-react";
import {
    Column,
    ColumnChooser,
    FilterPanel,
    SearchPanel,
    FilterRow,
    GroupPanel,
    HeaderFilter,
    Pager,
    Paging,
    StateStoring,
} from "devextreme-react/data-grid";
import { api } from "../../Api";
import { toast } from "react-toastify";
import { GeneralContext } from "../../Context/generalContext";
import { useNavigate } from "react-router-dom";

function Proyecto() {

    const navigate = useNavigate();
    const { setLoading } = React.useContext(GeneralContext);

    const [proyectos, setProyectos] = React.useState([]);

    React.useEffect(() => {
        setLoading(true)
        api.get('proyecto')
            .then((response) => {
                const { data } = response;
                setProyectos(data);
            })
            .catch(err => {
                toast.error(`Error al obtener los proyectos: ${err.message || 'Unhandled error'}`);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const toolBarColumn = (row) => {
        return (
            <Box>
                <IconButton
                    color="primary"
                    aria-label="Ver"
                    onClick={() => navigate(`/proyecto/${row.data.id}`)}
                >
                    <VisibilityIcon />
                </IconButton>
                <IconButton
                    color="warning"
                    aria-label="Editar"
                    onClick={() => navigate(`/proyecto/editar/${row.data.id}`)}
                >
                    <EditIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {/* Header (titulo y crear) */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ paddingX: 5 }}>
                        <h1>Proyectos activos</h1>
                    </Box>
                    <Box sx={{ paddingX: 5 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon className="text-quaternary" />}
                            onClick={() => navigate('/proyecto/crear')}
                        >
                            Crear
                        </Button>
                    </Box>
                </Grid>
                {/* Lista de proyectos */}
                <Grid item xs={12}>
                    <Box sx={{ paddingX: 5 }}>
                        <DataGrid
                            dataSource={proyectos}
                            allowColumnReordering={false}
                            allowColumnResizing={true}
                            columnResizingMode="nextColumn"
                        >
                            <StateStoring
                                enabled={true}
                                type="localStorage"
                                storageKey={'JWatchProyectosGrid'}
                            />
                            <GroupPanel visible={true} />
                            <ColumnChooser enabled={true} />
                            <Column
                                dataField="nombre"
                                caption="Nombre"
                                width={'auto'}
                            />
                            <Column
                                dataField="descripcion"
                                caption="Descripción"
                            />
                            <Column
                                dataField="id"
                                caption=""
                                width={'auto'}
                                allowFiltering={false}
                                allowSorting={false}
                                cellRender={toolBarColumn}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={false} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} />
                            <Paging defaultPageSize={50} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[50, 100, 150, 200]}
                                showInfo={true}
                            />
                        </DataGrid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Proyecto;