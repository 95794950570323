import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material';
import { GeneralProvider } from './Context/generalContext';

import 'devextreme/dist/css/dx.material.blue.light.compact.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1B263B',
      light: '#1B263B',
      dark: '#1B263B',
      contrastText: '#E0E1DD'
    },
    secondary: {
      main: '#415A77',
      light: '#415A77',
      dark: '#415A77',
      contrastText: '#0D1B2A'
    },

  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GeneralProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </GeneralProvider>
    </ThemeProvider>
  </BrowserRouter>
);
