import React from "react";
import { GeneralContext } from "../../Context/generalContext";
import { Grid, Tooltip, Button, ButtonGroup, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { api } from "../../Api";
import { copyToClipboard } from "../../Utils/functions";
import UptimeGraph from "./uptime-graph";

const STATUS = {
    1: {
        icon: <CheckIcon sx={{ color: '#36cf34' }} />,
        text: 'Excelente',
        color: '#36cf34'
    },
    2: {
        icon: <ErrorIcon sx={{ color: '#ffe924' }} />,
        text: 'Funcional',
        color: '#ffe924'
    },
    3: {
        icon: <WarningIcon sx={{ color: 'orange' }} />,
        text: 'Inestable',
        color: 'orange'
    },
    4: {
        icon: <CancelIcon sx={{ color: 'red' }} />,
        text: 'Crítico',
        color: 'red'
    }
}

const DATE_RANGE = {
    0: 'Último registro',
    1: '1h',
    2: '1d',
    3: '1sem',
    4: '1mes',
}

const RELOAD_INTERVAL = [
    {
        value: 0,
        label: 'Nunca'
    },
    {
        value: 30000,
        label: '30 segundos'
    },
    {
        value: 60000,
        label: '1 minuto'
    },
    {
        value: 300000,
        label: '5 minutos'
    },
    {
        value: 600000,
        label: '10 minutos'
    },
    {
        value: 900000,
        label: '15 minutos'
    },
]

function get_status(PERCENTAGE) {
    if (PERCENTAGE == 100) {
        return 1;
    } else if (PERCENTAGE >= 75) {
        return 2;
    } else if (PERCENTAGE >= 25) {
        return 3;
    } else {
        return 4;
    }
}

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function Status() {

    const swiperRef = React.useRef(null);

    const { loading, setLoading } = React.useContext(GeneralContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [proyectos, setProyectos] = React.useState([]);
    const [reloadInterval, setReloadInterval] = React.useState(RELOAD_INTERVAL[0]);
    const [currentInterval, setCurrentInterval] = React.useState(null);
    const [filters, setFilters] = React.useState({
        dateRange: 0
    });
    const [lastUpdate, setLastUpdate] = React.useState(null);
    const [projectDetail, setProjectDetail] = React.useState(null);

    React.useEffect(() => {
        setLoading(true)
        api.get('proyecto/status', {
            params: {
                ...filters,
                projectId: searchParams.get('projectId')
            }
        })
            .then((response) => {
                const { data } = response;
                // Se filtrarán los proyectos que no tengan keys, las keys sin componenten y los componentes sin STATUS
                // Si una key no tiene componentes con status, no se mostrará
                // Si un proyecto no tiene keys con componentes con status, no se mostrará
                setProyectos(data?.filter(item => item?.Keys?.length && item?.Keys?.filter(item => item.Components.length && item.Components?.filter(item => item?.STATUS?.PACKAGES).length).length).map(item => {
                    return {
                        ...item,
                        Keys: item?.Keys?.map(key => {
                            return {
                                ...key,
                                Components: key?.Components?.filter(item => item?.STATUS?.PACKAGES)
                            }
                        })
                    }
                }));
            })
            .catch(err => {
                toast.error(`Error al obtener los proyectos: ${err.message || 'Unhandled error'}`);
            })
            .finally(() => {
                setLoading(false);
                setLastUpdate(moment().format('YYYY-MM-DD HH:mm:ss'));
            });
    }, [filters]);

    function getEnvDetail(id) {
        setLoading(true);
        api.get(`key/${id}/status`, {
            params: {
                tz: currentTimezone
            }
        })
            .then((response) => {
                const { data } = response;
                setProjectDetail({
                    ...projectDetail,
                    componentes: data
                });
            })
            .catch(err => {
                toast.error(`Error al obtener los proyectos: ${err.message || 'Unhandled error'}`);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    React.useEffect(() => {
        swiperRef.current?.swiper?.update();
    }, [proyectos, projectDetail]);

    React.useEffect(() => {
        if (projectDetail && !projectDetail?.componentes) {
            getEnvDetail(projectDetail?.ambiente?.id);
        }
    }, [projectDetail]);

    React.useEffect(() => {
        if (reloadInterval.value === 0) {
            if (currentInterval) {
                clearInterval(currentInterval);
                setCurrentInterval(null);
            }
        } else {
            if (currentInterval) {
                clearInterval(currentInterval);
            }
            const intervalId = setInterval(() => {
                // Se "actualiza" el filtro para que se vuelva a hacer la petición
                setFilters({ ...filters, test: null })
            }, reloadInterval.value);
            setCurrentInterval(intervalId);
        }

        // Limpiar el intervalo cuando el componente se desmonte
        return () => {
            if (currentInterval) {
                clearInterval(currentInterval);
            }
        };
    }, [reloadInterval]);


    return (
        <div>
            <h1 className="text-primary" style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center' }}>
                {projectDetail && (
                    <IconButton
                        onClick={() => {
                            setProjectDetail(null);
                            if (swiperRef.current && swiperRef.current.swiper) {
                                swiperRef.current.swiper.slideTo(0);
                            }
                        }}
                        sx={{ marginRight: 2 }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                )}
                Status Info {projectDetail ? `${projectDetail?.nombre} - ${projectDetail?.ambiente?.entorno}` : ''}
            </h1>
            <Swiper
                autoHeight={true}
                ref={swiperRef}
            >
                {/* Slide principal (Vista general) */}
                <SwiperSlide>
                    <Grid container padding={2} justifyContent={'center'}>
                        <Grid container spacing={2} xs={12} md={10} justifyContent={'right'} alignContent={'center'}>
                            <Grid item xs={'auto'} md={'auto'} justifyContent={'right'} alignContent={'center'} display={'flex'}>
                                <span style={{ fontSize: '1em', fontWeight: 'bold', alignSelf: 'center' }}>
                                    Última actualización: {lastUpdate}
                                </span>
                            </Grid>
                            <Grid item xs={'auto'} md={'auto'} justifyContent={'right'} alignContent={'center'} display={'flex'}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={reloadInterval}
                                    options={RELOAD_INTERVAL}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Actualizar cada" />}
                                    onChange={(event, value) => {
                                        setReloadInterval(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={'auto'} md={'auto'} justifyContent={'right'} alignContent={'center'}>
                                <ButtonGroup variant="outlined" aria-label="date ranges">
                                    {Object.keys(DATE_RANGE).map((key) => {
                                        return (
                                            <Button
                                                key={key}
                                                onClick={() => {
                                                    setFilters({ ...filters, dateRange: key });
                                                }}
                                                variant={filters?.dateRange == key ? 'contained' : 'outlined'}
                                            >
                                                {DATE_RANGE[key]}
                                            </Button>
                                        )
                                    })}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container padding={2} justifyContent={'center'}>
                        {proyectos.filter(item => item?.Keys?.length).map((proyecto, index) => {
                            return (
                                <Grid container xs={12} md={10} sx={{ border: '1px solid #dbdbdb' }} marginY={1}>
                                    {/* Nombre del proyecto e iconos */}
                                    <Grid item xs={12} justifyContent={'space-between'} alignContent={'center'} display={'flex'} padding={2} sx={{ background: '#e3e1e1' }}>
                                        <Grid item xs={6} md={'auto'}>
                                            <span style={{ wordWrap: 'break-word', fontSize: '1.4em', fontWeight: 'bold' }}>
                                                {proyecto.nombre}&nbsp;
                                                <IconButton
                                                    onClick={() => {
                                                        copyToClipboard(`${window.location.origin}/status?projectId=${proyecto.id}`);
                                                        toast.success('Link copiado al portapapeles');
                                                    }}
                                                >
                                                    <LinkIcon />
                                                </IconButton>
                                            </span>
                                        </Grid>
                                        <Grid container xs={6} md={'auto'}>
                                            {Object.keys(STATUS).map((key) => {
                                                return (
                                                    <Grid item xs={6} md={'auto'} display={'flex'} alignContent={'center'}>
                                                        {STATUS[key].icon}
                                                        <span style={{ padding: '0px 15px 0px 5px' }}>
                                                            {STATUS[key].text}
                                                        </span>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                    {/* Ambientes */}
                                    {proyecto?.Keys?.filter(item => item.Components.length).map((ambiente, index) => {
                                        return (
                                            <>
                                                <Grid item xs={12} alignContent={'center'} display={'flex'} paddingX={3} paddingY={2} sx={{ background: '#f0eded' }}>
                                                    <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                                        {ambiente.entorno}&nbsp;
                                                        <IconButton
                                                            onClick={() => {
                                                                if (swiperRef.current && swiperRef.current.swiper) {
                                                                    swiperRef.current.swiper.slideTo(1);
                                                                    setProjectDetail({
                                                                        id: proyecto.id,
                                                                        nombre: proyecto.nombre,
                                                                        ambiente: ambiente
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </span>
                                                </Grid>
                                                <Grid container xs={12} alignContent={'center'} display={'flex'}  >
                                                    {ambiente?.Components?.filter(item => item?.STATUS?.PACKAGES).map((componente, index) => {
                                                        const { PERCENTAGE, AVG, DETAILS } = componente.STATUS;
                                                        const status = get_status(PERCENTAGE);
                                                        return (
                                                            <Grid container xs={12} md={6} paddingX={3} paddingY={2} sx={{ border: '1px solid #dbdbdb', }}>
                                                                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} >
                                                                    <span style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                                                        {componente.nombre}
                                                                    </span>
                                                                    <Tooltip
                                                                        style={{ cursor: 'pointer' }}
                                                                        title={
                                                                            <>
                                                                                {DETAILS?.map((detail, index) => {
                                                                                    const DATE = detail?.DATE?.split(' ').length > 1 ? moment.utc(detail?.DATE).local().format('YYYY-MM-DD HH:mm:ss') : detail?.DATE;
                                                                                    return (
                                                                                        <div key={index} style={{ fontWeight: 'bold' }}>
                                                                                            {DATE}
                                                                                            {detail?.STATUS.map((status, index) => {
                                                                                                return (
                                                                                                    <div key={index} style={{ fontWeight: 'normal' }}>
                                                                                                        {status}
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        }
                                                                    >
                                                                        {STATUS[status].icon}
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} >
                                                                    <span style={{ fontSize: '1em' }}>
                                                                        Promedio de respuesta: {AVG} ms
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>
                            )
                        })}
                        {/* Si no hay datos */}
                        {proyectos.length == 0 && !loading && (
                            <Grid item xs={12} justifyContent={'center'} alignContent={'center'} display={'flex'} padding={2}>
                                <span className="text-tertiary" style={{ fontWeight: 'bold', fontSize: '1em' }}>
                                    No hay datos para mostrar
                                </span>
                            </Grid>
                        )}
                    </Grid>
                </SwiperSlide>
                {/* Slide de detalle */}
                <SwiperSlide>
                    <Grid container padding={2} justifyContent={'center'}>
                        {projectDetail?.componentes?.map((componente, index) => {
                            return (
                                <UptimeGraph
                                    key={index}
                                    componente={componente}
                                />
                            )
                        })}
                    </Grid>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default Status;
export {
    STATUS,
    get_status,
}