import './App.css';
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Backdrop, CircularProgress } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css'
// Importar contextos
import { GeneralContext } from './Context/generalContext';
// Importar componentes
import AppBar from './Components/appBar'
import Proyecto from './Pages/Proyecto';
import Status from './Pages/Status';
import CrearProyecto from './Pages/Proyecto/crear';

function App() {

  const { loading, loadingMessage } = React.useContext(GeneralContext);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to={'/proyecto'} replace />} />
        <Route path="/proyecto" element={<AppBar />} >
          <Route index element={<Proyecto />} />
          <Route path="crear" element={<CrearProyecto />} />
          <Route path="editar/:id" element={<CrearProyecto />} />
          <Route path=":id" element={<CrearProyecto />} />
        </Route>
        <Route path="/status" element={<AppBar />} >
          <Route index element={<Status />} />
        </Route>
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Backdrop
        sx={{ display: 'flex', flexDirection: 'column', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        {loadingMessage && <h2>{loadingMessage}</h2>}
      </Backdrop>
    </div>
  );
}

export default App;
