const status_type = {
    URL: 0,
    DATABASE: 1,
}

const cronTranslation = {
    "Every": "Cada",
    "day(s)": "día(s)",
    "Every week day": "De lunes a viernes",
    "Start time": "Hora de inicio",
    "hour": "hora",
    "At": "A las",
    "minute(s)": "minuto(s)",
    "Day": "Día",
    "of every month(s)": "de cada mes",
    "Last day of every month": "Último día de cada mes",
    "On the last weekday of every month": "En el último día de la semana del mes",
    "day(s) before the end of the month": "día(s) antes del final del mes",
    "Monday": "Lunes",
    "Tuesday": "Martes",
    "Wednesday": "Miércoles",
    "Thursday": "Jueves",
    "Friday": "Viernes",
    "Saturday": "Sábado",
    "Sunday": "Domingo",
    "Minutes": "Minutos",
    "Hourly": "Horas",
    "Daily": "Días",
    "Weekly": "Semanas",
    "Monthly": "Meses",
    "Custom": "Personalizado",
    "Expression": "Expresión",
}

export {
    status_type,
    cronTranslation
}