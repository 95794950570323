import React from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from 'react-router-dom';
import {
    Grid, Box, IconButton, TextField, Button, MenuItem, Autocomplete,
    Dialog, DialogContent, DialogTitle, DialogActions, Tooltip
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LockIcon from '@mui/icons-material/Https';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleIcon from '@mui/icons-material/Schedule'

import AddIcon from '@mui/icons-material/Add';
import { api } from "../../../Api";
import { toast } from "react-toastify";
import { GeneralContext } from "../../../Context/generalContext";
import { copyToClipboard } from "../../../Utils/functions";
import { status_type, cronTranslation } from "../../../Utils/constants";
import Cron, { HEADER } from 'react-cron-generator'

function Crear() {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { setLoading } = React.useContext(GeneralContext);

    const [maestrosAll, setMaestrosAll] = React.useState([]);

    const [id, setId] = React.useState(null);
    const [nombre, setNombre] = React.useState('');
    const [descripcion, setDescripcion] = React.useState('');
    const [maestros, setMaestros] = React.useState([]);
    const [ambientes, setAmbientes] = React.useState([]);
    const [isCreating, setIsCreating] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [isViewing, setIsViewing] = React.useState(false);
    const [cronDialog, setCronDialog] = React.useState(null);

    React.useEffect(() => {
        const { pathname } = location;
        const { id } = params;
        if (pathname.includes('crear')) {
            setIsCreating(true);
        } else if (pathname.includes('editar')) {
            setIsEditing(true);
            setId(id);
        } else {
            setIsViewing(true);
        }
        setLoading(true);
        api.get('proyecto/maestros')
            .then((response) => {
                const { data } = response;
                setMaestrosAll(data);
                if (id) {
                    api.get(`proyecto/${id}`)
                        .then((response) => {
                            const { data } = response;
                            const { nombre, descripcion, ambientes, maestros } = data;
                            console.log(data);
                            setNombre(nombre);
                            setDescripcion(descripcion);
                            setAmbientes(ambientes);
                            setMaestros(maestros)
                        })
                        .catch(err => {
                            toast.error(`Error al obtener el proyecto: ${err.message || 'Unhandled error'}`);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    setLoading(false);
                }
            })
            .catch(err => {
                toast.error(`Error al obtener los maestros: ${err.message || 'Unhandled error'}`);
                setLoading(false);
            });

    }, []);


    const saveProyecto = () => {
        setLoading(true);
        const request = isCreating ? api.post('proyecto', { nombre, descripcion, ambientes, maestros }) : api.put(`proyecto/${id}`, { nombre, descripcion, ambientes, maestros });
        request
            .then((response) => {
                const { data } = response;
                toast.success(`Proyecto ${isCreating ? 'creado' : 'actualizado'} correctamente`);
                navigate('/proyecto');
            })
            .catch(err => {
                toast.error(`Error al guardar el proyecto: ${err.message || 'Unhandled error'}`);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <IconButton
                        color="primary"
                        aria-label="Ver"
                        sx={{ marginX: 5 }}
                        onClick={() => navigate('/proyecto')}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <h1 style={{ display: 'inline-block' }}>
                        {isCreating ? 'Crear' : isEditing ? 'Editar' : 'Información de'} Proyecto
                    </h1>
                </Grid>
                <Grid container xs={12} spacing={2} sx={{ marginX: 5, marginY: 2 }}>
                    <Grid item xs={12} md={10}>
                        <TextField
                            id="nombre"
                            label="Nombre"
                            variant="outlined"
                            fullWidth
                            required
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            InputProps={{
                                readOnly: isViewing
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            id="descripcion"
                            label="Descripción"
                            variant="outlined"
                            multiline
                            rows={4}
                            fullWidth
                            value={descripcion}
                            onChange={(e) => setDescripcion(e.target.value)}
                            InputProps={{
                                readOnly: isViewing
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Autocomplete
                            multiple
                            id="maestros"
                            options={maestrosAll}
                            getOptionLabel={(option) => option.tablaAlias}
                            getOptionKey={(option) => option.idMaestro}
                            value={maestrosAll.filter((maestro) => maestros.includes(maestro.idMaestro))}
                            onChange={(e, value) => {
                                setMaestros(value.map((maestro) => maestro.idMaestro));
                            }}
                            readOnly={isViewing}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Maestros"
                                    placeholder="Seleccionar..."
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginLeft: 5 }}>
                    <h2 style={{ display: 'inline-block' }}>
                        Ambientes
                    </h2>
                    {!isViewing &&
                        <IconButton
                            color="primary"
                            aria-label="Ver"
                            sx={{ marginX: 2 }}
                            onClick={() => {
                                let newAmbientes = [...ambientes];
                                newAmbientes.push({ nombre: '', componentes: [] });
                                setAmbientes(newAmbientes);
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    }
                </Grid>
                {ambientes.map((ambiente, index) => {
                    const { public_key } = ambiente;
                    return (
                        <Grid container xs={12} spacing={2} sx={{ marginX: 5, marginY: 2 }}>
                            {/* Nombre del ambiente */}
                            <Grid item xs={12} md={5} key={`ambiente-${index}-nombre`}>
                                <TextField
                                    id={`ambiente-${index}-nombre`}
                                    label="Nombre"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={ambiente.nombre || ambiente.entorno || ''}
                                    onChange={(e) => {
                                        let newAmbientes = [...ambientes];
                                        newAmbientes[index].nombre = e.target.value;
                                        setAmbientes(newAmbientes);
                                    }}
                                    InputProps={{
                                        readOnly: isViewing,
                                        startAdornment: !isViewing &&
                                            <IconButton
                                                onClick={() => {
                                                    let newAmbientes = [...ambientes];
                                                    newAmbientes.splice(index, 1);
                                                    setAmbientes(newAmbientes);
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                    }}
                                />
                            </Grid>
                            {/* Secret ARN */}
                            <Grid item xs={12} md={5} key={`ambiente-${index}-arn`}>
                                <TextField
                                    id={`ambiente-${index}-arn`}
                                    label="DB Secret ARN"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={ambiente.db_secret_arn || ''}
                                    onChange={(e) => {
                                        let newAmbientes = [...ambientes];
                                        newAmbientes[index].db_secret_arn = e.target.value;
                                        setAmbientes(newAmbientes);
                                    }}
                                    InputProps={{
                                        readOnly: isViewing
                                    }}
                                />
                            </Grid>
                            {isViewing && public_key && (
                                <Grid item xs={12} md={10} key={`ambiente-${index}-public-key`}>
                                    <TextField
                                        id={`ambiente-${index}-public-key`}
                                        label="Public Key"
                                        variant="outlined"
                                        fullWidth
                                        value={public_key}
                                        type={ambiente.showKey ? 'text' : 'password'}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment:
                                                <>
                                                    {!ambiente.showKey ?
                                                        <IconButton
                                                            onClick={() => {
                                                                let newAmbientes = [...ambientes];
                                                                newAmbientes[index].showKey = true;
                                                                setAmbientes(newAmbientes);
                                                            }}
                                                        >
                                                            <LockIcon />
                                                        </IconButton> :
                                                        <IconButton
                                                            onClick={() => {
                                                                copyToClipboard(public_key);
                                                            }}
                                                        >
                                                            <CopyIcon />
                                                        </IconButton>
                                                    }
                                                </>
                                        }}
                                    />
                                </Grid>
                            )}
                            {/* STATUS PAGES */}
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginLeft: 5 }}>
                                <h2 style={{ display: 'inline-block' }}>
                                    Componentes
                                </h2>
                                {!isViewing &&
                                    <IconButton
                                        color="primary"
                                        aria-label="Ver"
                                        sx={{ marginX: 2 }}
                                        onClick={() => {
                                            let newAmbientes = [...ambientes];
                                            const componentes = newAmbientes[index].componentes || [];
                                            componentes.push({ nombre: '', url: '', type: 0 });
                                            newAmbientes[index].componentes = componentes;
                                            setAmbientes(newAmbientes);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                }
                            </Grid>
                            {ambiente?.componentes?.map((componente, indexComponente) => {
                                return (
                                    <Grid container xs={12} spacing={2} sx={{ marginX: 5, marginY: 2 }}>
                                        {/* Nombre del componente */}
                                        <Grid item xs={12} md={3} key={`ambiente-${index}-componente-${indexComponente}-nombre`}>
                                            <TextField
                                                id={`ambiente-${index}-componente-${indexComponente}-nombre`}
                                                label="Nombre"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                value={componente.nombre || null}
                                                onChange={(e) => {
                                                    let newAmbientes = [...ambientes];
                                                    newAmbientes[index].componentes[indexComponente].nombre = e.target.value;
                                                    setAmbientes(newAmbientes);
                                                }}
                                                InputProps={{
                                                    readOnly: isViewing,
                                                    startAdornment: !isViewing &&
                                                        <IconButton
                                                            onClick={() => {
                                                                let newAmbientes = [...ambientes];
                                                                newAmbientes[index].componentes.splice(indexComponente, 1);
                                                                setAmbientes(newAmbientes);
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                }}
                                            />
                                        </Grid>
                                        {/* URL del componente */}
                                        <Grid item xs={12} md={3} key={`ambiente-${index}-componente-${indexComponente}-url`}>
                                            <TextField
                                                id={`ambiente-${index}-componente-${indexComponente}-url`}
                                                label={`${componente.type === status_type.DATABASE ? 'Secret ARN' : 'URL'}`}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                value={componente.url || ''}
                                                onChange={(e) => {
                                                    let newAmbientes = [...ambientes];
                                                    newAmbientes[index].componentes[indexComponente].url = e.target.value;
                                                    setAmbientes(newAmbientes);
                                                }}
                                                InputProps={{
                                                    readOnly: isViewing,
                                                }}
                                            />
                                        </Grid>
                                        {/* Tipo del componente */}
                                        <Grid item xs={12} md={2} key={`ambiente-${index}-componente-${indexComponente}-type`}>
                                            <TextField
                                                id={`ambiente-${index}-componente-${indexComponente}-type`}
                                                label="Tipo"
                                                variant="outlined"
                                                select
                                                fullWidth
                                                required
                                                value={componente?.type}
                                                onChange={(e) => {
                                                    let newAmbientes = [...ambientes];
                                                    newAmbientes[index].componentes[indexComponente].type = e.target.value;
                                                    setAmbientes(newAmbientes);
                                                }}
                                                InputProps={{
                                                    readOnly: isViewing,
                                                }}
                                            >
                                                {Object.keys(status_type).map((key) => {
                                                    return (
                                                        <MenuItem key={key} value={status_type[key]}>
                                                            {key}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        </Grid>
                                        {/* Frecuencia de medición */}
                                        <Grid item xs={12} md={2} key={`ambiente-${index}-componente-${indexComponente}-cron`}>
                                            <Tooltip title={componente?.cronText || ''} placement="top-start">
                                                <TextField
                                                    id={`ambiente-${index}-componente-${indexComponente}-cron`}
                                                    label="Frecuencia"
                                                    value={componente?.cronText || ''}
                                                    fullWidth
                                                    required
                                                    InputLabelProps={{ shrink: componente?.cronText }}
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <Tooltip
                                                                arrow
                                                                placement='top'
                                                                title={'Configurar frecuencia de medición'}
                                                            >
                                                                <IconButton
                                                                    onClick={() => {
                                                                        if (isViewing) return
                                                                        setCronDialog({ index, indexComponente })
                                                                    }}
                                                                >
                                                                    <ScheduleIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            {/* Linea divisoria */}
                            <hr style={{ width: '100%', border: '1px solid #e0e0e0', margin: '10px 0' }} />
                        </Grid>
                    );
                })}
                {/* DIALOGO DE CRON */}
                <Dialog
                    open={cronDialog?.index !== undefined && cronDialog?.indexComponente !== undefined}
                    onClose={() => setCronDialog(null)}
                    maxWidth="md"
                >
                    <DialogTitle>
                        Seleccione la frecuencia&nbsp;
                        <small style={{ fontSize: "0.6em" }}>
                            (Zona horaria UTC)
                        </small>
                    </DialogTitle>
                    <DialogContent sx={{ overflow: "hidden" }}>
                        <Cron
                            onChange={(cron, text) => {
                                try {
                                    let newAmbientes = [...ambientes];
                                    newAmbientes[cronDialog?.index].componentes[cronDialog?.indexComponente].cron = cron;
                                    newAmbientes[cronDialog?.index].componentes[cronDialog?.indexComponente].cronText = text;
                                    setAmbientes(newAmbientes);
                                } catch (error) {
                                    return
                                }
                            }}
                            locale="es"
                            value={ambientes[cronDialog?.index]?.componentes[cronDialog?.indexComponente]?.cron}
                            showResultText={true}
                            showResultCron={false}
                            translateFn={(value) => {
                                return cronTranslation[value]
                            }}
                            options={{
                                headers: [
                                    HEADER.MONTHLY, HEADER.WEEKLY,
                                    HEADER.MINUTES, HEADER.HOURLY,
                                    HEADER.DAILY, // HEADER.CUSTOM
                                ]
                            }}
                        />
                        <Button
                            onClick={() => {
                                let newAmbientes = [...ambientes];
                                newAmbientes[cronDialog?.index].componentes[cronDialog?.indexComponente].cron = null;
                                newAmbientes[cronDialog?.index].componentes[cronDialog?.indexComponente].cronText = null;
                                setAmbientes(newAmbientes);
                                setCronDialog(null);
                            }}
                            color="primary"
                            variant="outlined"
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            Borrar selección
                        </Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCronDialog(null)} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
                {!isViewing &&
                    <Grid item xs={12} md={10} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginY: 2 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                navigate('/proyecto');
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveProyecto();
                            }}
                            sx={{ marginRight: 5, marginLeft: 2 }}
                        >
                            {isCreating ? 'Crear' : 'Actualizar'}
                        </Button>
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

export default Crear;