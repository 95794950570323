import React from "react";
import moment from "moment";
import { Box, Grid, Tooltip } from "@mui/material";
import 'moment/locale/es'

import {
    Chart,
    Series,
    CommonSeriesSettings,
    Legend,
    Margin,
    Tooltip as ChartTooltip,
    Point
} from 'devextreme-react/chart';

import { STATUS, get_status } from ".";

moment.locale('es');

const RANGE = 90; // Rango de días a mostrar en el gráfico

// Genera una lista de fechas de los ultimos 90 días
const generateDateList = (customRange = RANGE) => {
    const dates = [];
    const today = moment();
    for (let i = 0; i < customRange; i++) {
        dates.push(today.clone().subtract(i, 'days').format('YYYY-MM-DD'));
    }
    return dates.reverse();
};

const calculateDateRange = () => {
    const width = window.innerWidth;
    let dates_amount = 0;
    if (width > 1682) {
        dates_amount = 90;
    } else if (width > 1592) {
        dates_amount = 75;
    } else if (width > 1435) {
        dates_amount = 60;
    } else if (width > 1210) {
        dates_amount = 45;
    } else if (width > 622) {
        dates_amount = 30;
    } else {
        dates_amount = 15;
    }
    return dates_amount;
}

const calculateDateWidth = (container_width, date_range) => {
    container_width = container_width - 20;
    let size = (container_width / date_range);
    while ((size * date_range) > container_width) {
        size -= 0.1;
    }
    return `${size - 4}px`;
}

// Componente que muestra el gráfico de uptime
// de los ultimos 90 días
function UptimeGraph({
    componente,
}) {

    const [dates, setDates] = React.useState([])
    const [dateWidth, setDateWidth] = React.useState(null);
    const [date_range, setDateRange] = React.useState(calculateDateRange());
    const dateRangeRef = React.useRef(date_range);
    const dateContainerRef = React.useRef(null);

    React.useEffect(() => {
        setDates(generateDateList(date_range));
        if (dateContainerRef.current) {
            setDateWidth(calculateDateWidth(dateContainerRef.current.offsetWidth, date_range));
        }
        // Resize
        const onResize = () => {
            const temp_range = calculateDateRange();
            if (temp_range !== dateRangeRef.current) {
                setDateRange(temp_range);
            }
        }
        // Se crea un observer para el resize del contenedor
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setDateWidth(calculateDateWidth(entry.contentRect.width, dateRangeRef.current))
            }
        });
        if (dateContainerRef.current) {
            resizeObserver.observe(dateContainerRef.current);
        }
        window.addEventListener('resize', onResize);
        // Cleanup
        return () => {
            window.removeEventListener('resize', onResize);
            if (dateContainerRef.current) {
                resizeObserver.unobserve(dateContainerRef.current);
            }
        }
    }, [])

    React.useEffect(() => {
        setDates(generateDateList(date_range));
        dateRangeRef.current = date_range;
    }, [date_range])

    return (
        <Grid container xs={12} md={8} padding={2} sx={{ border: '1px #d9d9d9 solid' }}>
            <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                {componente?.nombre}
            </span>
            <Grid container xs={12} paddingY={2} justifyContent={'center'} ref={dateContainerRef}>
                {dates.map((date, index) => {
                    // Se genera status aleatorio del 1 al 4 sin 0
                    // para simular el uptime;
                    const { percentage } = componente?.logs[date] || {};
                    const status = percentage != undefined ? get_status(percentage) : null;
                    return (
                        <Tooltip
                            key={index}
                            title={
                                <div>
                                    <span>
                                        {moment(date).format('LL')}
                                    </span>
                                    <br />
                                    <br />
                                    <span
                                        style={{ fontSize: 12 }}
                                    >
                                        {status ? STATUS[status].text : 'Sin datos'}
                                    </span>
                                    <br />
                                    {Object.keys(componente?.logs[date] || {}).filter(hour => componente?.logs[date][hour]?.status !== 'OK\n' && hour != 'percentage').map((hour, index) => {
                                        const { status } = componente?.logs[date][hour];
                                        return (
                                            <>
                                                <br />
                                                <span
                                                    key={index}
                                                    style={{ fontSize: 12, whiteSpace: 'pre-line' }}
                                                >
                                                    <b>{hour} hrs</b>
                                                    <br />
                                                    {status}
                                                </span>
                                            </>
                                        )
                                    })}
                                </div>
                            }
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: 'white',
                                        color: 'black',
                                        boxShadow: 3,
                                        fontSize: 14,
                                    },
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    background: status ? STATUS[status].color : '#d9d9d9',
                                    height: '35px',
                                    width: dateWidth || '1px',
                                    margin: '2px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        filter: 'grayscale(50%)',
                                    },
                                }}
                            />
                        </Tooltip>
                    )
                })}
            </Grid>
            <Grid item xs={12} justifyContent={'center'}>
                <Chart
                    dataSource={componente?.avg?.filter((avg) => dates.includes(avg.date)).reverse()}
                >
                    <ChartTooltip
                        enabled={true}
                        customizeTooltip={(arg) => {
                            return {
                                text: arg.rangeValue1 ? `${arg.rangeValue1}ms - ${arg.rangeValue2}ms` : `${arg.value}ms`
                            };
                        }}
                        zIndex={9999}
                    />
                    <CommonSeriesSettings
                        argumentField="date"
                    />
                    <Series
                        type="line"
                        valueField="max"
                        name="Max"
                        color="#9370DB"
                    >
                        <Point visible={false} />
                    </Series>
                    <Series
                        type="rangearea"
                        rangeValue1Field="min"
                        rangeValue2Field="max"
                        color="#E6E6FA"
                        name={"Avg"}
                    />
                    <Series
                        type="line"
                        valueField="min"
                        name="Min"
                        color="#3CB371"
                    >
                        <Point visible={false} />
                    </Series>
                    <Margin bottom={20} />
                    <Legend
                        verticalAlignment="top"
                        horizontalAlignment="right"
                    />
                </Chart>
            </Grid>
        </Grid>
    )
}

export default UptimeGraph;