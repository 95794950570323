import React, { useState, useEffect } from "react";
const GeneralContext = React.createContext();

const GeneralProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(null);

    React.useEffect(() => {
        if(!loading) {
            setLoadingMessage(null);
        }
    }, [loading])

    return (
        <GeneralContext.Provider
            value={{
                loading,
                loadingMessage,
                setLoading,
                setLoadingMessage
            }}
        >
            {children}
        </GeneralContext.Provider>
    );
};

export { GeneralContext, GeneralProvider };
