import axios from 'axios';

const LOCAL = 'LOCAL'
const DEV = 'DEV'
const PROD = 'PROD'

const environ = process.env.REACT_APP_BUILD_ENV || LOCAL

const baseURL = `${environ == PROD ? 'https://ut19v988lc.execute-api.us-east-1.amazonaws.com' :
    environ == DEV ? 'https://3xitucu0sd.execute-api.us-east-1.amazonaws.com' :
        'http://localhost:3001'}/api/v1/`

const api = axios.create({
    baseURL,
});

export {
    api
}